import './App.css';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { useSearchParams } from "react-router-dom";
import LaunchSimplicity from "./LaunchSimplicity";
import { OAuthAuthenticator } from "./OAuthAuthenticator";
import { Flex } from "@aws-amplify/ui-react";

Amplify.configure( {
   Auth: {
      Cognito: {
         userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
         userPoolClientId: process.env.REACT_APP_COGNITO_APP_INTEGRATION_ID,
         loginWith: {
            oauth: {
               domain: process.env.REACT_APP_OAUTH_DOMAIN,
               scopes: process.env.REACT_APP_OAUTH_SCOPES.split( ' ' ),
               redirectSignIn: [ process.env.REACT_APP_REDIRECT_SIGNIN ],
               redirectSignOut: [ process.env.REACT_APP_REDIRECT_SIGNOUT ],
               responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE,
            },
            username: true,
         },
      }
   }
} );

export default function App()
{
   const [ searchParams ] = useSearchParams();
   return (
      <Flex id="content" direction="column" alignContent="center" minHeight={0}>
         <OAuthAuthenticator passedState={ { dataToken : searchParams.get( 'token' ),
            sessionType : searchParams.get( 'sessionType' ) } }>
            {
            ( signOut, customState ) =>
               (
               <>
                  <LaunchSimplicity
                     signOut={ signOut }
                     customState={ customState }
                  />
               </>
               )
            }
         </OAuthAuthenticator>
      </Flex>
   )
}